import { Box, IconButton, Input, ListItem, ListItemText } from '@mui/material';
import { Delete, Save } from '@mui/icons-material';
import { FormEvent, useRef, useState } from 'react';
import { useDeleteReferenceMutation, useUpdateReferenceMutation } from './referencesApiSlice';
import { ReferenceOptionsType, ReferencesSlug } from '../../types/common';
import { useGlobalError } from '../../hooks/useGlobalError';
import { useRoles } from '../../hooks/useRole';
import { FieldArrayInput } from './FieldArrayInput';

interface ItemProps {
  item: ReferenceOptionsType;
  slug: ReferencesSlug;
}

const ReferenceItem = ({ item, slug }: ItemProps) => {
  const [edit, setEdit] = useState(false);
  const [inputValueName, setInputValueName] = useState(item.name);
  const [inputValueType, setInputValueType] = useState(item.type);

  const [fieldArrayValue, setFieldArryValue] = useState<ReferenceOptionsType | null>(null);
  const [fieldArrayInputValue, setFieldArrayInputValue] = useState('');

  const formRef = useRef<HTMLFormElement>(null);

  const { isAdmin } = useRoles();

  const disableEdit = !isAdmin;

  const [
    updateReference,
    { isLoading: isUpdating, isError: isErrorUpdating, error: errorUpdating },
  ] = useUpdateReferenceMutation();

  const [
    deleteReference,
    { isLoading: isDeleting, isError: isErrorDeleting, error: errorDeleting },
  ] = useDeleteReferenceMutation();

  const handleSave = async (e: FormEvent) => {
    e.preventDefault();
    try {
      await updateReference({
        slug,
        id: item.id,
        name: inputValueName,
        ...(slug === 'unit-measurements' && { type: inputValueType }),
        ...(slug === 'fields' && { field_array: fieldArrayValue?.id }),
      }).unwrap();
      setEdit(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLFormElement, Element>) => {
    if (formRef.current && !formRef.current.contains(event.relatedTarget as Node)) {
      if (
        inputValueName === item.name &&
        inputValueType === item.type &&
        (fieldArrayInputValue === item.field_array?.name ||
          fieldArrayValue?.name === item.field_array?.name)
      ) {
        setEdit(false);
      }
    }
  };

  useGlobalError(isErrorUpdating, errorUpdating);
  useGlobalError(isErrorDeleting, errorDeleting);

  return (
    <ListItem
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="delete"
          disabled={isDeleting || disableEdit}
          onClick={() => deleteReference({ slug, id: item.id })}
        >
          <Delete color={disableEdit ? 'disabled' : 'error'} />
        </IconButton>
      }
    >
      <Box
        component="form"
        sx={{ display: 'flex', flex: 1, py: 0.5, cursor: disableEdit ? 'default' : 'pointer' }}
        ref={formRef}
        onSubmit={handleSave}
        onClick={(e) => {
          e.stopPropagation();
          if (disableEdit) return;
          setEdit(true);
        }}
        noValidate
        onBlur={handleBlur}
      >
        {edit || isUpdating ? (
          <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
            <Input
              value={inputValueName}
              onChange={(e) => setInputValueName(e.target.value)}
              autoFocus
              fullWidth
              sx={{
                height: '40px',
                paddingY: '4px',
                '&:focus-within': { backgroundColor: '#f7f7f7' },
              }}
              error={isErrorUpdating}
              name={slug + ' name'}
            />
            {item.type && (
              <Input
                value={inputValueType}
                onChange={(e) => setInputValueType(e.target.value)}
                fullWidth
                sx={{
                  height: '40px',
                  paddingY: '4px',
                  '&:focus-within': { backgroundColor: '#f7f7f7' },
                }}
                error={isErrorUpdating}
                name={slug + ' type'}
              />
            )}
            <FieldArrayInput
              item={item}
              fieldArrayValue={fieldArrayValue}
              setFieldArryValue={setFieldArryValue}
              fieldArrayInputValue={fieldArrayInputValue}
              setFieldArrayInputValue={setFieldArrayInputValue}
              slug={slug}
              err={isErrorUpdating}
            />
          </Box>
        ) : (
          <ListItemText
            primary={`${item.type ? item.type + ', ' : ''}${item.name}${item.field_array ? ', ' + item.field_array.name : ''}`}
          />
        )}
        <Box sx={{ marginX: 2, display: 'flex', alignItems: 'center' }}>
          {(edit || isUpdating) && (
            <IconButton disabled={isUpdating} type="submit" edge="end" aria-label="edit">
              <Save />
            </IconButton>
          )}
        </Box>
      </Box>
    </ListItem>
  );
};

export default ReferenceItem;
