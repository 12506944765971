import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDeleteHarvestMutation, useGetHarvestQuery } from './harvestApiSlice';
import { RoutesEnum } from '../../constants/routes';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Range, RangeKeyDict } from 'react-date-range';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { getDateInYYMMDD } from '../../utils/text';
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { Add, BarChartOutlined, Close } from '@mui/icons-material';

import { useGlobalError } from '../../hooks/useGlobalError';
import { LIMIT_PER_PAGE_HERVEST } from '../../constants/harvest';
import { Loaader } from '../../components/Loader';
import { HarvestListItemAdmin } from './HarvestListItemAdmin';
import { HarvestListItemSecurity } from './HarvestListItemSecurity';
import { useRoles } from '../../hooks/useRole';
import { useDebouncedCallback } from 'use-debounce';
import { useSelector } from 'react-redux';
import { selectCurrentRole, selectCurrentToken } from '../auth/authSlice';
import axios from 'axios';
import { Harvest } from '../../types/harvest';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';
import { AppDatePicker } from '../../components/AppDatePicker';
import { InfiniteScrollTriggerDiv } from '../../components/InfiniteScrollTriggerDiv';

const HarvestList = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const token = useSelector(selectCurrentToken);
  const role = useSelector(selectCurrentRole);

  const { isAdmin, isAgronomist, isController, isSecurityHpp, isSecurityMobile } = useRoles();

  const minDate = searchParams.get('min_created_at');
  const maxDate = searchParams.get('max_created_at');
  const today = getDateInYYMMDD(new Date());

  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [page, setPage] = useState(Number(searchParams.get('page') || '1'));
  const [isLoadingExport, setIsloadingExport] = useState(false);
  const [errorExporting, setErrorExporting] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [fetchAfterDelete, setFetchAfterDelete] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [refetch, setRefetch] = useState(0);

  const [range, setRange] = useState<Range[]>([
    {
      startDate: !!minDate ? new Date(minDate) : new Date(),
      endDate: !!maxDate ? new Date(maxDate) : new Date(),
      key: 'selection',
    },
  ]);

  const navigate = useNavigate();

  const searchRef = useRef<HTMLInputElement>(null);

  const debounced = useDebouncedCallback((value) => {
    setSearchValue(value);
    if (page !== 1) {
      setPage(1);
      const startDate = searchParams.get('min_created_at');
      const endDate = searchParams.get('max_created_at');
      setSearchParams({
        ...(startDate && { min_created_at: startDate }),
        ...(endDate && { max_created_at: endDate }),
        page: '1',
      });
    }
  }, 600);

  const [
    deleteHarvest,
    { isError: isErrorDeleting, error: errorDeleting, isSuccess: isSuccessDeleting },
  ] = useDeleteHarvestMutation();

  const {
    data: harvest,
    isLoading,
    isFetching,
    isSuccess,
    isError,
    error,
    status,
  } = useGetHarvestQuery(
    {
      minDate: minDate || today,
      maxDate: maxDate || today,
      offset: 0,
      limit: page * LIMIT_PER_PAGE_HERVEST,
      search: searchValue,
      refetch,
    },
    { skip: !isFirstLoad && !fetchAfterDelete && !refetch },
  );

  const {
    data: harvestNext,
    isFetching: isFetchingNext,
    isSuccess: isSuccessNext,
    isError: isErrorNext,
    error: errorNext,
    status: statusNext,
    originalArgs: originalArgsNext,
  } = useGetHarvestQuery(
    {
      minDate: minDate || today,
      maxDate: maxDate || today,
      page: page,
      search: searchValue,
    },
    {
      skip: isFirstLoad,
    },
  );

  const handlePageChange = () => {
    setIsFirstLoad(false);
    setPage(page + 1);
    const startDate = searchParams.get('min_created_at');
    const endDate = searchParams.get('max_created_at');
    setSearchParams({
      ...(startDate && { min_created_at: startDate }),
      ...(endDate && { max_created_at: endDate }),
      page: (page + 1).toString(),
    });
  };

  const [combined, ref] = useInfiniteScroll<Harvest>(
    LIMIT_PER_PAGE_HERVEST,
    harvest,
    isSuccess,
    isFetching,
    status,
    isSuccessNext,
    isFetchingNext,
    statusNext,
    originalArgsNext?.page,
    page,
    harvestNext,
    isError,
    error,
    isErrorNext,
    errorNext,
    handlePageChange,
    setFetchAfterDelete,
    isSuccessDeleting,
  );

  const handleExport = () => {
    setIsloadingExport(true);
    setErrorExporting(undefined);
    axios(
      `${process.env.REACT_APP_API_URL}harvest/export?offset=0&limit=${page * LIMIT_PER_PAGE_HERVEST}${!!searchValue ? `&search=${searchValue}` : ''}&min_created_at=${new Date(new Date(minDate || today).valueOf() + new Date(minDate || today).getTimezoneOffset() * 60 * 1000).toISOString()}&max_created_at=${new Date(new Date(maxDate || today).valueOf() + (24 * 60 * 60 * 1000 - 1) + new Date(maxDate || today).getTimezoneOffset() * 60 * 1000).toISOString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          CurrentRole: role || '',
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        responseType: 'blob',
      },
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(async (e) => {
        const responseObj = await e?.response?.data?.text();
        setErrorExporting({ data: JSON.parse(responseObj), ...e?.response?.data });
      })
      .finally(() => setIsloadingExport(false));
  };

  const debounceRangeChange = useDebouncedCallback((currRange: Range[]) => {
    setPage(1);
    setSearchParams({
      min_created_at: getDateInYYMMDD(new Date(currRange[0].startDate || '')),
      max_created_at: getDateInYYMMDD(new Date(currRange[0].endDate || '')),
    });
  }, 600);

  const handleRangeChange = (rangesByKey: RangeKeyDict) => {
    const currRange = [rangesByKey.selection];
    setRange(currRange);
    debounceRangeChange(currRange);
  };

  useEffect(() => {
    const interval = setInterval(() => setRefetch((prev) => prev + 1), 60 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [minDate, maxDate, page, searchValue]);

  useGlobalError(isErrorDeleting, errorDeleting);
  useGlobalError(!!errorExporting, errorExporting);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
        <IconButton
          disabled={isLoadingExport}
          edge="end"
          aria-label="delete"
          onClick={handleExport}
        >
          <BarChartOutlined sx={{ color: '#888' }} />
        </IconButton>
        <AppDatePicker
          range={range}
          handleRangeChange={handleRangeChange}
          isDatePickerVisible={isDatePickerVisible}
          setIsDatePickerVisible={setIsDatePickerVisible}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
        <Box sx={{ position: 'relative', flex: 1, whiteSpace: 'balance' }}>
          <TextField
            variant="outlined"
            defaultValue={''}
            margin="none"
            id="search"
            label="Пошук"
            name="search"
            fullWidth
            onChange={(e) => debounced(e.target.value)}
            sx={{ marginTop: 1 }}
            inputRef={searchRef}
          />
          {!!searchValue && (
            <IconButton
              sx={{ position: 'absolute', right: 5, top: 24 }}
              onClick={() => {
                setSearchValue('');
                if (searchRef.current?.value) {
                  searchRef.current.value = '';
                }
              }}
            >
              <Close />
            </IconButton>
          )}
        </Box>
        {(isAdmin || isAgronomist) && (
          <Button onClick={() => navigate('/' + RoutesEnum.addHarvest)} key={'add'} sx={{ gap: 1 }}>
            <Add sx={{ fontSize: '0.9rem' }} />
            <ListItemText
              primaryTypographyProps={{
                fontWeight: 500,
                fontSize: '0.8rem',
                whiteSpace: 'pre',
                textAlign: 'left',
              }}
              primary={`ДОДАТИ \n ЗБІР`}
            />
          </Button>
        )}
      </Box>
      {isLoading ? (
        <Loaader />
      ) : (
        <>
          <List>
            <Divider />
            {!combined?.total && (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
                <Typography variant="body2">Немає зборів</Typography>
              </Box>
            )}
            {combined?.data?.map((item) => {
              return (
                item && (
                  <Box
                    key={item.bill_of_lading}
                    sx={{ pointerEvents: isDatePickerVisible ? 'none' : 'all' }}
                  >
                    {(isAdmin || isAgronomist || isController) && (
                      <HarvestListItemAdmin item={item} deleteHarvest={deleteHarvest} />
                    )}
                    {(isSecurityHpp || isSecurityMobile) && <HarvestListItemSecurity item={item} />}
                    <Divider />
                  </Box>
                )
              );
            })}
            <InfiniteScrollTriggerDiv
              divRef={ref}
              isFetching={isFetching}
              isFetchingNext={isFetchingNext}
              total={combined?.total}
              page={page}
              limit={LIMIT_PER_PAGE_HERVEST}
            />
          </List>
        </>
      )}
    </>
  );
};

export default HarvestList;
