import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#0c99ff',
    },
    secondary: {
      main: '#ff9d00',
    },
    success: {
      main: '#52be2c',
    },
    error: {
      main: '#dd0d0ded',
    },
  },
  typography: {
    h1: { fontSize: '1.8rem', fontWeight: 600, color: '#186716' },
  },
});
