const v = process.env.REACT_APP_VERSION || 0;
const CACHE_NAME = 'app-cache' + v;
//cache for 2 days
const EXPIRATION_TIME = 1000 * 60 * 60 * 24 * 2;

export const clearExpiredCache = () => {
  try {
    caches.open(CACHE_NAME).then((cache) => {
      cache.matchAll().then((responses) =>
        responses.map((response) => {
          const creationDate = response.headers.get('time-cached') || response.headers.get('date');
          if (
            response.url &&
            (!creationDate || +new Date(creationDate) + EXPIRATION_TIME < +new Date())
          ) {
            cache.delete(response.url);
          }
        }),
      );
    });
  } catch (err) {
    console.log(err);
  }
};
