import { Autocomplete, TextField } from '@mui/material';
import { ReferenceOptionsType, ReferencesSlug } from '../../types/common';
import { useAddReferenceMutation, useGetReferencesQuery } from './referencesApiSlice';
import { useEffect } from 'react';
import { useGlobalError } from '../../hooks/useGlobalError';
import { filterAndSortOptions } from '../../utils/filterOptions';

interface IProps {
  item?: ReferenceOptionsType;
  fieldArrayValue: ReferenceOptionsType | null;
  setFieldArryValue: React.Dispatch<React.SetStateAction<ReferenceOptionsType | null>>;
  fieldArrayInputValue: string;
  setFieldArrayInputValue: React.Dispatch<React.SetStateAction<string>>;
  slug: ReferencesSlug;
  err?: boolean;
}

export const FieldArrayInput = ({
  item,
  fieldArrayValue,
  setFieldArryValue,
  fieldArrayInputValue,
  setFieldArrayInputValue,
  slug,
  err,
}: IProps) => {
  const { data, isLoading, isError, error } = useGetReferencesQuery(
    { slug: 'field-array' },
    { skip: slug !== 'fields' },
  );

  const [addReference, { isLoading: isLoadingAdding, isError: isErrorAdding, error: errorAdding }] =
    useAddReferenceMutation();

  const handleAddNew = async (newValue: string) => {
    const newArrData = await addReference({
      slug: 'field-array',
      name: newValue,
    }).unwrap();

    if (newArrData?.id) {
      setFieldArryValue({ id: newArrData?.id, name: newValue });
    }
  };

  useEffect(() => {
    if (item?.field_array && !!data && !fieldArrayValue && !fieldArrayInputValue) {
      setFieldArryValue(data.find((field) => field.id === item.id) || null);
    }
  }, [data, item]);

  useGlobalError(isError, error);
  useGlobalError(isErrorAdding, errorAdding);

  return (
    <>
      {slug === 'fields' && (
        <Autocomplete
          disabled={isLoadingAdding}
          id="combo-box-demo"
          loading={isLoading || isLoadingAdding}
          options={data || []}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            if (option.inputValue) {
              return option.name || option.inputValue;
            }
            return option.name;
          }}
          value={fieldArrayValue}
          fullWidth
          freeSolo
          onChange={async (event, newValue) => {
            if (typeof newValue === 'string') {
              setFieldArryValue({
                name: newValue,
              });
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              setFieldArryValue({
                name: newValue.inputValue,
              });
              await handleAddNew(newValue.inputValue);
            } else {
              setFieldArryValue(newValue);
            }
          }}
          filterOptions={(options, { inputValue }) => {
            const filtered = filterAndSortOptions(
              options,
              inputValue,
              (option) => (option as ReferenceOptionsType).name,
            ) as ReferenceOptionsType[];

            const isExisting = options.some((option) => inputValue === option.name);

            if (!!inputValue && inputValue.length >= 2 && !isExisting) {
              filtered.push({
                inputValue,
                name: `+ Створити масив "${inputValue}"`,
              });
            }

            return filtered;
          }}
          noOptionsText={null}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={(e) => setFieldArrayInputValue(e.target.value)}
              fullWidth
              sx={{
                height: '40px',
                paddingY: '4px',
                '& .MuiInputBase-root:focus-within': { backgroundColor: '#f7f7f7' },
              }}
              error={isError || isErrorAdding || err}
              name={slug}
              variant="standard"
              placeholder="масив"
            />
          )}
        />
      )}
    </>
  );
};
