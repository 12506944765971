import * as React from 'react';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import { useLoginMutation } from './authApiSlice';
import { useDispatch } from 'react-redux';
import { setCredentials } from './authSlice';
import { RoutesEnum } from '../../constants/routes';
import { jwtDecode } from 'jwt-decode';
import { AuthUser } from '../../types/auth';
import PasswordInput from '../../components/PasswordInput';

const Login = () => {
  const userRef = useRef<HTMLDivElement | null>(null);
  const errRef = useRef<HTMLParagraphElement | null>(null);
  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [formChecked, setFormChecked] = useState(false);

  const navigate = useNavigate();

  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormChecked(true);

    if (!pwd || !user) return;

    try {
      const userData = await login({ login: user, password: pwd }).unwrap();
      dispatch(setCredentials({ ...userData }));
      const decodedJWT: AuthUser = jwtDecode(userData?.access_token);
      const roles = decodedJWT?.roles;
      setUser('');
      setPwd('');
      roles?.length && roles.length > 1 ? navigate('/' + RoutesEnum.chooseRole) : navigate('/');
    } catch (err: any) {
      console.log(err);
      if (!err?.data) {
        setErrMsg('No Server Response');
      } else {
        !!err.data?.detail ? setErrMsg(err.data?.detail) : setErrMsg('Login failed');
      }
      errRef.current?.focus();
    }
  };

  const handleUserInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setUser(e.target.value);
  const handlePwdInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setPwd(e.target.value.trim());

  useEffect(() => {
    userRef.current?.focus();
  }, []);

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h1">
          Сварог Вест Груп
        </Typography>

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Ім'я користувача"
            name="email"
            autoFocus
            onChange={handleUserInput}
            ref={userRef}
            value={user}
            error={!!errMsg || (formChecked && !user)}
          />
          <PasswordInput
            password={pwd}
            name={'password'}
            label={'Пароль'}
            required={true}
            autoComplete={'current-password'}
            onChange={handlePwdInput}
            error={!!errMsg || (formChecked && !pwd)}
          />
          <Box sx={{ display: 'inline' }}>
            {!!errMsg && (
              <Typography
                component="p"
                variant="body2"
                sx={{
                  color: 'error.main',
                  my: 2,
                  position: '',
                  margin: '0',
                }}
                ref={errRef}
              >
                {errMsg}
              </Typography>
            )}
          </Box>
          <Button
            disabled={isLoading}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, color: 'white' }}
          >
            Увійти в систему
          </Button>
          <Grid container>
            <Grid item xs={9}>
              <Link href={RoutesEnum.forgotPwd} variant="body2">
                Забули пароль?
              </Link>
            </Grid>
            <Grid item xs={3} textAlign={"right"}>
                v{process.env.REACT_APP_VERSION}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
