import { CalendarMonth } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { DateRangePicker, RangeKeyDict, Range } from 'react-date-range';
import { getDateRangeText } from '../utils/text';

interface IProps {
  range: Range[];
  handleRangeChange: (rangesByKey: RangeKeyDict) => void;
  isDatePickerVisible: boolean;
  setIsDatePickerVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AppDatePicker = ({
  range,
  handleRangeChange,
  isDatePickerVisible,
  setIsDatePickerVisible,
}: IProps) => {
  const calendarRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLButtonElement>(null);

  const handleClickOutside = (event: Event) => {
    if (btnRef.current && btnRef.current.contains(event.target as Node)) {
      return;
    } else if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
      setIsDatePickerVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        position: 'relative',
        gap: 1,
      }}
    >
      <Typography color="primary.main" variant="body2" sx={{ paddingTop: '4px' }}>
        {getDateRangeText(range)}
      </Typography>
      <IconButton ref={btnRef} onClick={() => setIsDatePickerVisible((prev) => !prev)}>
        <CalendarMonth />
      </IconButton>
      {isDatePickerVisible && (
        <Box
          sx={{
            position: 'absolute',
            top: '38px',
            right: '-4px',
            background: '#fff',
            zIndex: 2,
            border: 'solid 1px rgba(0, 0, 0, 0.12)',
          }}
          ref={calendarRef}
        >
          <DateRangePicker
            moveRangeOnFirstSelection={false}
            editableDateInputs={true}
            ranges={range}
            onChange={handleRangeChange}
            months={1}
            showMonthAndYearPickers={false}
            showDateDisplay={false}
            displayMode="dateRange"
            staticRanges={[]}
            renderStaticRangeLabel={() => <></>}
            inputRanges={[]}
          />
        </Box>
      )}
    </Box>
  );
};
