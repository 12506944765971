import { FormEvent, useRef, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import { checkIfError, parseErrorObj } from '../../utils/text';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutesEnum } from '../../constants/routes';
import { Errortype } from '../../types/common';
import { useChangePasswordMutation } from './profileApiSlice';
import { validatePasswordCriteria } from '../../utils/validatePasswordCriteria';
import PasswordCriteria from '../../components/PasswordCriteria';
import PasswordInput from '../../components/PasswordInput';
import { setForcePasswordChange } from '../auth/authSlice';
import { useDispatch } from 'react-redux';

const ChangePwdForm = () => {
  const [changePassword, { isLoading }] = useChangePasswordMutation();

  const [old_password, setOld_password] = useState('');
  const [new_password, setNew_password] = useState('');
  const [confirm_password, setConfirm_password] = useState('');
  const [formChecked, setFormChecked] = useState(false);
  const [err, setErr] = useState<Errortype>();

  const errRef = useRef<HTMLParagraphElement | null>(null);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { change_default } = useParams();

  const isDefaultChangePwd = change_default === 'change_default';

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormChecked(true);
    if (!validatePasswordCriteria(new_password)) return;
    if (!old_password || !new_password || !confirm_password) return;
    if (new_password !== confirm_password) return;
    try {
      await changePassword({ old_password, new_password }).unwrap();
      if (isDefaultChangePwd) {
        dispatch(setForcePasswordChange(false));
        navigate('/');
      } else {
        navigate('/' + RoutesEnum.profile);
      }
    } catch (err: any) {
      console.log(err);
      if (!err?.data) {
        setErr({ data: { detail: 'No Server Response' } });
      } else {
        !!err.data?.detail ? setErr(err) : setErr({ data: { detail: 'Request failed' } });
      }
      errRef.current?.focus();
    }
  };

  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 4,
        }}
      >
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          {isDefaultChangePwd && (
            <Typography component="h1" variant="h5" sx={{ textAlign: 'center' }}>
              Необхідно оновити пароль
            </Typography>
          )}
          <PasswordInput
            password={old_password}
            name={'old_password'}
            label={'Старий пароль'}
            required={true}
            onChange={(e) => setOld_password(e.target.value.trim())}
            error={checkIfError(old_password, 'old_password', formChecked, err, true)}
          />
          <PasswordInput
            password={new_password}
            name={'new_password'}
            label={'Новий пароль'}
            required={true}
            onChange={(e) => setNew_password(e.target.value.trim())}
            error={
              checkIfError(new_password, 'new_password', formChecked, err, true) ||
              (formChecked && !validatePasswordCriteria(new_password))
            }
          />
          <PasswordCriteria password={new_password} />

          <PasswordInput
            password={confirm_password}
            name={'confirm_password'}
            color={
              new_password === confirm_password && confirm_password !== '' ? 'success' : 'error'
            }
            label={'Підтвердіть пароль'}
            required={true}
            onChange={(e) => setConfirm_password(e.target.value.trim())}
            error={
              checkIfError(confirm_password, 'confirm_password', formChecked, err, true) ||
              (formChecked && new_password !== confirm_password)
            }
          />
          <Box sx={{ display: 'inline-block', marginTop: 1 }}>
            {!!err && (
              <Typography
                component="p"
                variant="body2"
                sx={{
                  color: 'error.main',
                  my: 2,
                  position: '',
                  margin: '0',
                }}
                ref={errRef}
              >
                {parseErrorObj(err)}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: 2,
              mt: 3,
              mb: 2,
            }}
          >
            {!isDefaultChangePwd && (
              <Button onClick={() => navigate('/' + RoutesEnum.profile)}>Відмінити</Button>
            )}
            <Button disabled={isLoading} type="submit" variant="contained">
              Зберегти
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ChangePwdForm;
